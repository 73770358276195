*, *:after, *:before {
    box-sizing: border-box;
    font: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
}

.logo-animation {
    animation-name: logo-animation;
    animation-duration: 3.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    /*margin-top: 20px;*/
    padding: 10px;
}

.bg-whitesmoke {
    background-color: whitesmoke;
}

@keyframes logo-animation {
    0% {
        opacity: 0.3;

    }
    50% {
        opacity: 1;

    }
    100% {
        opacity: 0.3;
    }
}
textarea{
    resize: none;
}
